
import { defineComponent, ref, PropType } from 'vue';

import AppIcon from '@/components/stateless/AppIcon.vue';
import AppBadge from '@/components/stateless/AppBadge.vue';
import AppTruncatedTooltip from '@/components/stateless/AppTruncatedTooltip.vue';

import { ITaskRes } from '@/types';

export default defineComponent({
  name: 'GoalTask',

  components: {  AppIcon, AppBadge, AppTruncatedTooltip },

  props: {
    task: {
      type: Object as PropType<ITaskRes>,
      required: true
    },

    index: {
      type: Number,
      required: true
    }
  },

  emits: ['delete', 'update'],

  setup() {
    let showMore = ref<boolean>(false);

    return {
      showMore
    };
  }

});
