
  import { defineComponent, PropType, toRefs, reactive, computed } from 'vue';
  import { useVuelidate } from "@vuelidate/core";
  import { required, helpers } from "@vuelidate/validators";

  import AppButton from '@/components/stateless/AppButton.vue';
  import AppTextarea from '@/components/stateless/AppTextarea.vue';
  import AppDatePicker from '@/components/stateless/AppDatePicker.vue';
  import AppInput from '@/components/stateless/AppInput.vue';

  import { ITaskData, IErrorFields } from '@/types';
  import { clearErrorField, handleSetErrors } from '@/core/helper-functions';

  export default defineComponent({
    name: 'CreateTaskForm',

    components: { AppDatePicker, AppTextarea, AppInput, AppButton },

    props: {
      data: {
        type: Object as PropType<ITaskData>,
        required: true
      },

      isGoalSelected: {
        type: Boolean,
        defautl: false
      },

      stage: {
        type: String,
        default: 'create'
      }
    },

    emits: ['create', 'close', 'update'],

    setup(props, { emit }) {
      const { data, stage } = toRefs(props);

      const pickerOptions = {
        // Disable past dates  "8.64e7" Need to subtract the number from current timestamp so that we don't disable "today" 
        disabledDate(time: any) { return time.getTime() < Date.now() - 8.64e7; }
      };

      const v$ = useVuelidate({
        title: { required: helpers.withMessage('This field cannot be empty', required) },
        targetDate: {},
        description: {}
      }, data.value);

      const errorMessages = reactive<IErrorFields>({ title: '' });

      const dataForSubmit = computed<ITaskData>(() => ({
        title: data.value.title,
        description: data.value.description ? data.value.description : null,
        targetDate: data.value.targetDate ? data.value.targetDate : null
      }));

      async function handleValidate() {
        const isValid = await v$.value.$validate();

        if (isValid) { return true; }
        else {
          handleSetErrors(v$.value.$errors, errorMessages);
          return false;
        }
      }

      async function onSubmit() {

        if (await handleValidate()) {
          if (stage.value === 'update') {
            emit('update', { data: dataForSubmit.value, editIndex: data.value.editIndex });
          } else {
            emit('create', dataForSubmit.value);
          }
        }
      }

      return {
        v$,
        errorMessages,

        onSubmit,
        handleValidate,
        pickerOptions,
        clearErrorField: (name: string) => clearErrorField(name, errorMessages),
      };
    }

  });
