import { required, helpers } from "@vuelidate/validators";

const addGoalRules = {
  category: {
    required: helpers.withMessage('This field cannot be empty', required),
  },
  goalName: {
    required: helpers.withMessage('This field cannot be empty', required),
  }
};

const updateGoalRules = {
  goalName: {
    required: helpers.withMessage('This field cannot be empty', required),
  }
};

export { addGoalRules, updateGoalRules }; 