<template>
  <div class="flex flex-col md:flex-row pb-25 border-b border-grey-fp-10 items-start w-full">
    <div class="w-full md:w-8/12 pr-20 mb-15 md:mb-0">
      <AppTruncatedTooltip class="text-grey-fp-70 mb-5" :content="task.title" /> 
      <p class="text-grey-fp-60 text-sm max-h-70 overflow-y-auto">{{ task.description }}</p>
    </div>
    <div class="flex justify-between w-full md:w-4/12 pr-15">
      <AppBadge 
        icon-name="calendar" 
        :icon-size="10"
        :content="`Target Date: ${task.targetDate ? $filters.date(task.targetDate, 'DD/MM/YYYY'): '-'}`" 
        class="text-xs text-lucky-orange"
        custom-classes="inline-flex" 
      />
      <div class="flex">
        <AppIcon name="pen" size="18" class="text-primary cursor-pointer" @click="$emit('update', index)" />
        <AppIcon 
          name="trash-alt" 
          size="18" 
          class="text-error ml-15 cursor-pointer" 
          @click="$emit('delete', index)" 
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, PropType } from 'vue';

import AppIcon from '@/components/stateless/AppIcon.vue';
import AppBadge from '@/components/stateless/AppBadge.vue';
import AppTruncatedTooltip from '@/components/stateless/AppTruncatedTooltip.vue';

import { ITaskRes } from '@/types';

export default defineComponent({
  name: 'GoalTask',

  components: {  AppIcon, AppBadge, AppTruncatedTooltip },

  props: {
    task: {
      type: Object as PropType<ITaskRes>,
      required: true
    },

    index: {
      type: Number,
      required: true
    }
  },

  emits: ['delete', 'update'],

  setup() {
    let showMore = ref<boolean>(false);

    return {
      showMore
    };
  }

});
</script>