<template>
  <div class="app-datepicker relative flex flex-col w-full text-dark-cl-20">
    <!-- L A B E L -->
    <div v-if="$slots.label || label" class="flex items-center justify-between">
      <div class="mb-10 text-sm sm:text-base text-dark-cl-20">
        <span v-if="!$slots.label">{{ label }}</span>
        <slot name="label" />
      </div>
    </div>

    <!-- D A T E P I C K E R -->
    <div class="relative" :class="{ 'invisible h-0': $slots.trigger }">
      <v-date-picker
        ref="datepicker"
        v-model="date"
        v-bind="filteredAttrs"
        prefix-icon="none"
        clear-icon="none"
        :disabled-date="disabledDateCb"
        :class="{ 'error': err.message }"
        popper-class="app-datepicker"
        @focus="onFocus"
        @blur="onBlur"
      />
      <div 
        :class="[{ 'bg-primary': isOpen, 'bg-red-fp-30': err.message && !isOpen }]" 
        class="calendar-icon cursor-edit" 
        @click="openDatepicker"
      >
        <AppIcon name="calendar" size="20" class="text-white" />
      </div>
    </div>

    <slot name="trigger" :openDatepicker="openDatepicker" />

    <!-- E R R O R -->
    <p
      v-if="err.message"
      class="text-red-fp-30 absolute text-sm sm:text-base top-full left-0 mt-2 flex items-center whitespace-nowrap"
    >
      <span class="mr-3">{{ err.message }}</span>
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, reactive, ref, toRefs } from 'vue';

import AppIcon from '@/components/stateless/AppIcon.vue';

import { IAppInputError } from '@/types';

export default defineComponent({
  name: 'AppDatePicker',

  components: { AppIcon },

  props: {
    modelValue: {
      type: [Date, String],
      required: true
    },

    error: {
      type: [String, Object as () => IAppInputError],
      default: ''
    },
    
    label: {
      type: String,
      default: ''
    },

    pickerOptions: {
      type: Object,
      default: null
    }
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const datepicker: any = ref(null);
    let isOpen = ref<boolean>(false);

    const { error, pickerOptions, modelValue } = toRefs(props);

    const err = computed<IAppInputError>(() => {
      if (typeof error.value === 'string') {
       return reactive<IAppInputError>({ message: error.value, link: { name: '', message: '' } });
      }

      return error.value; 
    });

    const disabledDateCb = computed<any>(() => {
      return pickerOptions.value && pickerOptions.value.disabledDate ? pickerOptions.value.disabledDate : null ;
    });

    let date = computed<string | number>({
      get: () => {
        return modelValue.value ? modelValue.value : '';
      },
      set: val => {
        if (val) {
          emit('update:modelValue', val);
        } else {
          emit('update:modelValue', '');
        }
      }
    });

    function openDatepicker () { datepicker.value.focus(); }

    function onFocus () { isOpen.value = true; }

    function onBlur () { isOpen.value = false; }

    return {
      err,
      date,
      datepicker,
      isOpen,
      disabledDateCb,

      openDatepicker,
      onFocus,
      onBlur
    };
  },

  computed: {
    filteredAttrs() {
      // TODO this is just workaround to remove binding classes
      let copyAttrs = { ...this.$attrs } as any;
      delete copyAttrs.class;

      return copyAttrs;
    }
  }

});
</script>
<style lang="scss" scoped>
  .calendar-icon {
    @apply absolute flex items-center justify-center 
            text-1xl h-26 w-26 right-15 rounded-half bg-grey-fp-50 
            transform -translate-y-1/2 top-1/2;
  }
</style>