<template>
  <div class="relative">
    <p class="mb-10 text-dark-cl-20">Goal</p>
    <div
      class="py-20 rounded-5 border border-grey-fp-50"
      :class="{ 'border-error': error }"
    >
      <ul>
        <li
          v-for="(item, index) of predefinedGoals"
          :key="index"
          class="py-6 hover:bg-blue-fp-40 text-grey-fp-80 cursor-pointer px-20 break-all"
          :class="{'bg-blue-fp-40': modelValue === item.title}"
          @click="handleChooseGoal(item)"
        >
          {{ item.title }}
        </li>
      </ul>

      <!-- A D D  Y O U R  O W N  G O A L  B U T T O N -->
      <div class="px-20 mt-12">
        <span
          v-if="!isAddOwnGoalOpen"
          class="inline-block cursor-pointer text-xs underline h-36"
          :class="disabled ? 'text-grey-fp-60' : 'text-primary'"
          @click="onAddOwnGoalClick"
        >
          Add your own
        </span>

        <div
          v-if="isAddOwnGoalOpen"
          class="flex items-center"
        >
          <AppInput
            ref="goalInput"
            :value="goal"
            name="goal"
            class="w-full"
            maxlength="140"
            @input="handleInputGoal"
            @focus="$emit('focus')"
          />

          <AppIcon
            name="trash-alt"
            class="text-error cursor-pointer ml-26"
            size="16"
            @click="onTrashIconClick"
          />
        </div>
      </div>
    </div>
    <p
      v-if="error"
      class="text-error mt-5"
    >
      {{ error }}
    </p>
    <div
      v-if="disabled"
      class="h-full w-full bg-white absolute inset-0 opacity-50 cursor-not-allowed"
    />
  </div>
</template>

<script lang="ts">
  import { defineComponent, ref, PropType } from 'vue';

  import AppInput from '@/components/stateless/AppInput.vue';
  import AppIcon from '@/components/stateless/AppIcon.vue';

  import { IGoal } from '@/types';

  export default defineComponent({
    name: 'GoalChooser',

    components: { AppInput, AppIcon },

    props: {
      modelValue: {
        type: String,
        default: ''
      },

      predefinedGoals: {
        type: Array as PropType<IGoal[]>,
        default: null
      },

      disabled: {
        type: Boolean,
        default: false
      },

      error: {
        type: String,
        default: ''
      }
    },

    emits: ['update:modelValue', 'showCustom', 'change', 'focus'],

    setup(props, { emit }) {
      let goal = ref<string>('');
      let goalInput = ref(null);
      let isAddOwnGoalOpen = ref<boolean>(false);

      function handleInputGoal(e: InputEvent) {
        const target = e.target as HTMLInputElement;

        emit('update:modelValue', target.value);
      }
      // TODO add type
      function handleChooseGoal(value: any) {
        emit('update:modelValue', value.title);
        emit('change', value);
        emit('focus');
        isAddOwnGoalOpen.value = false;
      }

      function onAddOwnGoalClick() {
        isAddOwnGoalOpen.value = true;
        emit('showCustom');
        emit('focus');
      }

      function onTrashIconClick() {
        isAddOwnGoalOpen.value = false;
        emit('update:modelValue', '');
        emit('change');
      }

      return {
        goal,
        isAddOwnGoalOpen,
        goalInput,

        handleChooseGoal,
        handleInputGoal,
        onAddOwnGoalClick,
        onTrashIconClick
      };
    }
  });
</script>