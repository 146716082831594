
import { defineComponent, computed, reactive, ref, toRefs } from 'vue';

import AppIcon from '@/components/stateless/AppIcon.vue';

import { IAppInputError } from '@/types';

export default defineComponent({
  name: 'AppDatePicker',

  components: { AppIcon },

  props: {
    modelValue: {
      type: [Date, String],
      required: true
    },

    error: {
      type: [String, Object as () => IAppInputError],
      default: ''
    },
    
    label: {
      type: String,
      default: ''
    },

    pickerOptions: {
      type: Object,
      default: null
    }
  },

  emits: ['update:modelValue'],

  setup(props, { emit }) {
    const datepicker: any = ref(null);
    let isOpen = ref<boolean>(false);

    const { error, pickerOptions, modelValue } = toRefs(props);

    const err = computed<IAppInputError>(() => {
      if (typeof error.value === 'string') {
       return reactive<IAppInputError>({ message: error.value, link: { name: '', message: '' } });
      }

      return error.value; 
    });

    const disabledDateCb = computed<any>(() => {
      return pickerOptions.value && pickerOptions.value.disabledDate ? pickerOptions.value.disabledDate : null ;
    });

    let date = computed<string | number>({
      get: () => {
        return modelValue.value ? modelValue.value : '';
      },
      set: val => {
        if (val) {
          emit('update:modelValue', val);
        } else {
          emit('update:modelValue', '');
        }
      }
    });

    function openDatepicker () { datepicker.value.focus(); }

    function onFocus () { isOpen.value = true; }

    function onBlur () { isOpen.value = false; }

    return {
      err,
      date,
      datepicker,
      isOpen,
      disabledDateCb,

      openDatepicker,
      onFocus,
      onBlur
    };
  },

  computed: {
    filteredAttrs() {
      // TODO this is just workaround to remove binding classes
      let copyAttrs = { ...this.$attrs } as any;
      delete copyAttrs.class;

      return copyAttrs;
    }
  }

});
