<template>
  <div class="container-md">
    <!-- C R E A T E  G O A L  F O R M  -->
    <div class="p-25 bg-white rounded-5 mb-20">
      <h1 class="text-1xl text-dark-cl-20 mb-25">Add a Goal</h1>

      <form
        class="max-w-320"
        @submit.prevent="onSubmit"
      >
        <AppSelect
          v-model="v$.category.$model"
          :options="categoryList"
          :error="errorMessages.category"
          label="Category"
          class="mb-20"
          option-key="name"
          key-value="name"
          placeholder="Select category"
          @change="onCategoryChange"
          @focus="clearErrorField('category')"
        />

        <GoalChooser
          v-model="v$.goalName.$model"
          :disabled="!category.id"
          :error="category.id && errorMessages.goalName"
          :predefined-goals="predefinedGoalsList"
          @show-custom="onShowCustomGoalInput"
          @change="onGoalChanged"
          @focus="clearErrorField('goalName')"
        />
      </form>
    </div>

    <!-- C R E A T E  T A S K  F O R M  -->
    <div class="relative p-25 bg-white rounded-5 mb-20">
      <p
        v-if="goalName"
        class="text-1xl text-dark-cl-20 mb-25"
      >
        Task (Optional)
      </p>

      <!-- E X I S T  T A S K S  -->
      <div class="max-h-250 overflow-y-auto">
        <GoalTask
          v-for="(task, index) of tasksList"
          :key="index"
          :index="index"
          :task="task"
          class="mb-25"
          @update="setDataToUpdateTask"
          @delete="onTaskDelete"
        />
      </div>

      <AppButton
        type="success"
        class="mr-10 px-20"
        icon-size="12"
        icon="plus"
        plain
        @click="onCreateNewTask"
      >
        Create New Task
      </AppButton>

      <div
        v-if="!goalName"
        class="h-full w-full bg-white absolute inset-0 opacity-50 cursor-not-allowed"
      />
    </div>

    <!-- A C T I O N  B U T T O N S -->
    <div class="action-menu">
      <AppButton
        type="primary"
        class="mr-10 px-0 md:px-60 w-full md:w-auto"
        @click="onSubmit"
      >
        Save Goal
      </AppButton>

      <AppButton
        class="px-0 md:px-20 w-full md:w-auto"
        plain
        @click="onCancel"
      >
        Cancel
      </AppButton>
    </div>
  </div>

  <!-- C R E A T E  T A S K  M O D A L  -->
  <portal
    v-if="showCreateTaskForm"
    to="default"
  >
    <AppModal @cancel="toggleShowCreateTaskForm">
      <div class="w-full min-w-320 max-w-360">
        <p class="text-dark-cl-20 text-md mb-28">{{ taskFormStage === 'create' ? 'Create New' : 'Update' }} Task</p>
        <CreateTaskFrom
          ref="createTaskRef"
          :data="taskForm"
          :is-goal-selected="!!goalName"
          :stage="taskFormStage"
          @create="onCreateTask"
          @update="onTaskUpdate"
          @close="toggleShowCreateTaskForm"
        />
      </div>
    </AppModal>
  </portal>

  <!-- U N S A V E D  C H A N G E S   H A N D L E R  -->
  <UnsavedChangesModal
    :disabled="disabledHandleChanges"
    :data="referenceCopyForm"
  />
</template>

<script lang="ts">
  import { defineComponent, ref, reactive, toRefs, onMounted, computed, watchEffect } from 'vue';
  import { useVuelidate } from "@vuelidate/core";
  import { useToast } from "vue-toastification";

  import AppSelect from '@/components/stateless/AppSelect.vue';
  import AppModal from '@/components/stateless/AppModal.vue';
  import AppButton from '@/components/stateless/AppButton.vue';
  import GoalChooser from '@/views/user-options/my-goals/components/GoalChooser.vue';
  import GoalTask from '@/views/user-options/my-goals/components/GoalTask.vue';
  import CreateTaskFrom from './components/CreateTaskForm.vue';
  import UnsavedChangesModal from '@/components/UnsavedChangesModal.vue';

  import { IBreadCrumb, ITaskRes, ITaskData, ICategory, IGoal, IErrorFields, IPredefinedGoal, IGoalsResponse, ICategoryRes } from '@/types';
  import { addGoalRules } from './my-goal-validation-rules';
  import { myGoalsService, categoriesService } from '@/services';
  import { vuex } from '@/store';
  import { handleSetErrors, clearErrorField } from '@/core/helper-functions';
  import { routesNames, router } from '@/router';
  import { CATEGORIES, predefinedGoalsParams } from '@/views/user-options/api-params';

  export default defineComponent({
    name: 'AddGoal',

    components: {
      AppSelect,
      GoalChooser,
      GoalTask,
      AppButton,
      CreateTaskFrom,
      AppModal,
      UnsavedChangesModal
    },

    setup() {
      const toast = useToast();
      const createTaskRef = ref(null);

      const state = reactive({
        predefinedGoalsList: [] as IGoal[],
        category: {} as ICategory,
        categoryList: [] as ICategory[],
        tasksList: [] as Partial<ITaskData[]>,
        taskForm: {} as ITaskData,
        referenceCopyForm: {} as any
      });

      let goalName = ref<string>('');
      let selectedPredefinedGoal = reactive({}) as IPredefinedGoal | null;
      let showCreateTaskForm = ref<boolean>(false);
      let {
        category,
        predefinedGoalsList,
        categoryList,
        tasksList,
        taskForm,
        referenceCopyForm
      } = toRefs(state);

      const disabledHandleChanges = ref<boolean>(false);

      const errorMessages = reactive<IErrorFields>({
        category: '',
        goalName: '',
        title: ''
      });

      const v$ = useVuelidate(
        {
          ...addGoalRules,
          goalName: computed(() => category.value.id ? addGoalRules.goalName : {}),
        },
        { category, goalName }
      );

      watchEffect(() => {
        // Need for checking changes
        referenceCopyForm.value.category = category.value;
        referenceCopyForm.value.title = goalName.value;
        referenceCopyForm.value.tasks = tasksList.value;
        referenceCopyForm.value.parentId = selectedPredefinedGoal && selectedPredefinedGoal.id
          ? selectedPredefinedGoal.id
          : null;

        const data: any = {
          categoryId: category.value.id,
          title: goalName.value,
          tasks: [],
          parentId: selectedPredefinedGoal && selectedPredefinedGoal.id ? selectedPredefinedGoal.id : undefined
        };

        if (tasksList.value.length) {
          data.tasks = tasksList.value;
        }
      });

      const breadCrumbsItems = [
        { to: '/home', text: 'Home' },
        { to: '/user-options/my-goals', text: 'My Goals' },
        { to: '/user-options/my-goals/add-goal', text: 'Add Goal' },
      ] as IBreadCrumb[];

      const taskFormStage = computed<string>(() => typeof taskForm.value.editIndex === 'number' ? 'update' : 'create');

      async function onCategoryChange() {
        goalName.value = '';
        resetTaskData();

        await fetchPredefinedGoals();
      }

      function handlePredefinedTasks() {
        if (!predefinedGoalsList.value.length) { return; }

        const checkedGoal = predefinedGoalsList.value.filter((el: IGoal) => el.title === goalName.value);
        const predefinedTasks = checkedGoal[0] && checkedGoal[0].tasks ? checkedGoal[0].tasks : [];

        tasksList.value = [
          ...tasksList.value,
          ...predefinedTasks.map(
            ({ title, description, targetDate }: ITaskRes) => ({ title, description, targetDate })
          )
        ];
      }

      function onCreateTask(payload: ITaskData) {
        tasksList.value = [...tasksList.value, payload];
        toggleShowCreateTaskForm();
        clearTaskFormFields();
      }

      function onTaskDelete(index: number) {
        tasksList.value.splice(index, 1);
      }

      function onTaskUpdate({ data, editIndex }: any) {
        tasksList.value.splice((editIndex as number), 1, data);
        toggleShowCreateTaskForm();
        clearTaskFormFields();
      }

      function setDataToUpdateTask(index: number) {
        const {
          title,
          targetDate,
          description
        } = tasksList.value.find((el: any, i: number) => i === index) as ITaskData;

        taskForm.value = { title, targetDate: targetDate || '', description: description || '', editIndex: index };
        toggleShowCreateTaskForm();
      }

      function onShowCustomGoalInput() {
        goalName.value = '';
        selectedPredefinedGoal = null;
        resetTaskData();
      }

      function resetTaskData() {
        showCreateTaskForm.value = false;
        tasksList.value = [];
        clearTaskFormFields();
      }

      function toggleShowCreateTaskForm() {
        showCreateTaskForm.value = !showCreateTaskForm.value;
      }

      function clearTaskFormFields() {
        taskForm.value.title = '';
        taskForm.value.description = '';
        taskForm.value.targetDate = '';
        taskForm.value.editIndex = null;
      }

      function onCreateNewTask() {
        clearTaskFormFields();
        toggleShowCreateTaskForm();
      }

      function onGoalChanged(predefinedGoal: IPredefinedGoal) {
        selectedPredefinedGoal = predefinedGoal;

        resetTaskData();
        handlePredefinedTasks();
      }

      function getDataForSubmit() {
        // TODO Add type
        const data: any = {
          categoryId: category.value.id,
          title: goalName.value,
          tasks: [],
          parentId: selectedPredefinedGoal && selectedPredefinedGoal.id ? selectedPredefinedGoal.id : undefined
        };

        if (tasksList.value.length) {
          data.tasks = tasksList.value;
        }

        return data;
      }

      function onCancel() {
        router.push({ name: routesNames.root });
      }

      function setDataForHandleChange() {
        referenceCopyForm.value.category = category.value;
        referenceCopyForm.value.title = goalName.value;
        referenceCopyForm.value.tasks = tasksList.value;
        referenceCopyForm.value.parentId = null;
      }

      async function onSubmit() {
        // Check if task form valid
        if (createTaskRef.value) {
          const isValidTaskForm = await (createTaskRef.value as any).handleValidate();
          if (!isValidTaskForm) { return; }
          await (createTaskRef.value as any).onSubmit();
        }

        if (await v$.value.$validate()) {
          vuex.setLoading(true);

          await myGoalsService.createGoal(getDataForSubmit())
            .then((res: IGoal) => {
              disabledHandleChanges.value = true;
              toast.success('Goal has been successfully created');
              router.push({ name: routesNames.myGoal, query: { id: res.id } });
            })
            .catch(({ response }: any) => {
              const { data } = response;
              toast.error(data.message);
            })
            .finally(() => vuex.setLoading(false));
        }
        else { handleSetErrors(v$.value.$errors, errorMessages); }
      }

      async function fetchPredefinedGoals() {
        vuex.setLoading(true);

        return myGoalsService.fetchPredefinedGoals(predefinedGoalsParams(category.value.id))
          .then((res: IGoalsResponse) => { predefinedGoalsList.value = res.data; })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      async function fetchCategories() {
        vuex.setLoading(true);

        return categoriesService.fetchCategories(CATEGORIES)
          .then((res: ICategoryRes) => { categoryList.value = res.data; })
          .catch(({ response }: any) => {
            const { data } = response;
            toast.error(data.message);
          })
          .finally(() => vuex.setLoading(false));
      }

      onMounted(async () => {
        await fetchCategories();
        setDataForHandleChange();
      });

      return {
        breadCrumbsItems,
        category,
        predefinedGoalsList,
        goalName,
        showCreateTaskForm,
        categoryList,
        errorMessages,
        taskForm,
        referenceCopyForm,
        disabledHandleChanges,

        createTaskRef,

        v$,
        tasksList,
        taskFormStage,

        onCategoryChange,
        onShowCustomGoalInput,
        resetTaskData,
        onGoalChanged,
        onSubmit,
        onCancel,
        onCreateTask,
        onTaskDelete,
        onTaskUpdate,
        setDataToUpdateTask,
        toggleShowCreateTaskForm,
        onCreateNewTask,
        clearErrorField: (name: string) => clearErrorField(name, errorMessages),
      };
    }
  });
</script>

<style lang="scss" scoped>
  .action-menu {
    @apply flex items-center justify-center md:bg-transparent bg-white p-14 rounded-5;
  }
</style>