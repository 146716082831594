import { IRequestParams } from '@/types';

export const ACTIVITIES_PAGINATION_LIMIT = 10;

export const registeredActivitiesParams =
  (id: number | string, offset?: number, limit = ACTIVITIES_PAGINATION_LIMIT): IRequestParams => ({
    params: {
      filter: {
        limit,
        offset,
        where: {
          categoryId: id === 'all' ? undefined : id,
          endDate: { $gt: new Date().toISOString() }
        },
        order: [
          [
            "placementFlag",
            "DESC"
          ],
          [
            "startDate", "asc"
          ]
        ],
        include: [
          {
            association: "registrations",
            required: true,
            where: {
              childId: null,
              status: {
                $in: [
                  "initialCancelled",
                  "activityCancelled",
                  "active"
                ]
              }
            }
          },
          {
            association: "category"
          },
          { model: "category" }
        ]
      },
      includeCount: true
    }
  });

export const activitiesWithoutRegistrationsParams =
  (id: number | string, offset?: number, limit = ACTIVITIES_PAGINATION_LIMIT): IRequestParams => ({
    params: {
      filter: {
        limit,
        offset,
        order: [
          [
            "placementFlag",
            "DESC"
          ],
          [
            "startDate", "asc"
          ]
        ],
        where: {
          endDate: { $gt: new Date().toISOString() },
          $and: [
            {
              $or: [
                {
                  "$registrations.status$": "initial"
                },
                {
                  "$registrations.id$": null
                }
              ]
            },
            {
              categoryId: id === 'all' ? undefined : id,
              status: "active"
            }
          ]
        },
        include: [
          {
            association: "registrations",
            where: {
              childId: null,
              status: {
                $ne: "cancelled"
              }
            }
          },
          {
            association: "category"
          },
          { model: "category" }
        ]
      },
      includeCount: true
    }
  });

export const predefinedGoalsParams = (id?: number): IRequestParams => ({
  params: {
    filter: {
      include: [
        {
          model: "task"
        }
      ],
      where: {
        familyId: null,
        categoryId: id
      }
    },
    includeCount: true
  }
});

export const GOAL_PARAMS: IRequestParams = {
  params: {
    filter: {
      include: [
        { model: 'task' },
        { model: "category" }
      ]
    }
  }
};

export const CATEGORIES: IRequestParams = {
  params: {
    filter: {
      where: {
        status: 'active'
      },
    }
  }
};

export const EXPLORE_ACTIVITIES_CATEGORIES: IRequestParams = {
  params: {
    filter: {
      where: {
        "$or": [
          {
            "status": "active"
          },
          {
            "status": "archived",
            "$activities.id$": {
              "$not": null
            }
          }
        ]
      },
      "include": [
        {
          "association": "activities",
          "where": {
            "endDate": {
              "$gt": new Date().toISOString()
            },
            "status": "active"
          }
        }
      ],
    }
  }
};