
  import { defineComponent, ref, PropType } from 'vue';

  import AppInput from '@/components/stateless/AppInput.vue';
  import AppIcon from '@/components/stateless/AppIcon.vue';

  import { IGoal } from '@/types';

  export default defineComponent({
    name: 'GoalChooser',

    components: { AppInput, AppIcon },

    props: {
      modelValue: {
        type: String,
        default: ''
      },

      predefinedGoals: {
        type: Array as PropType<IGoal[]>,
        default: null
      },

      disabled: {
        type: Boolean,
        default: false
      },

      error: {
        type: String,
        default: ''
      }
    },

    emits: ['update:modelValue', 'showCustom', 'change', 'focus'],

    setup(props, { emit }) {
      let goal = ref<string>('');
      let goalInput = ref(null);
      let isAddOwnGoalOpen = ref<boolean>(false);

      function handleInputGoal(e: InputEvent) {
        const target = e.target as HTMLInputElement;

        emit('update:modelValue', target.value);
      }
      // TODO add type
      function handleChooseGoal(value: any) {
        emit('update:modelValue', value.title);
        emit('change', value);
        emit('focus');
        isAddOwnGoalOpen.value = false;
      }

      function onAddOwnGoalClick() {
        isAddOwnGoalOpen.value = true;
        emit('showCustom');
        emit('focus');
      }

      function onTrashIconClick() {
        isAddOwnGoalOpen.value = false;
        emit('update:modelValue', '');
        emit('change');
      }

      return {
        goal,
        isAddOwnGoalOpen,
        goalInput,

        handleChooseGoal,
        handleInputGoal,
        onAddOwnGoalClick,
        onTrashIconClick
      };
    }
  });
